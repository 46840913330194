import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import {IMG_BASE_PATH, numToCommaAmount, toCommaAmount} from "../../Constants/constants";
import { removeItemFromCart } from "../../features/cart/cartSlice";

export default function CartItem({ data }) {
  const dispatch = useDispatch();

  async function deleteItem() {
    dispatch(removeItemFromCart({ id: data._id, price: data.price }));

    const res = await axios.delete(
      `http://localhost:5000/user/Cart/delete/${data._id}`
    );
  }

  return (
    <li className="flex py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={IMG_BASE_PATH + data.image_url_small}
          alt="Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt."
          className="h-full w-full object-cover object-center"
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>
              <a href="#">{data.name}</a>
            </h3>
            <p className="ml-4">{numToCommaAmount(data.price)}</p>
          </div>
        </div>
        <br />
        <div className="flex flex-1 items-end justify-between text-sm">
          <p className="mt-1 text-sm text-gray-500">{data.level}</p>

          <div className="flex">
            <button
              onClick={deleteItem}
              type="button"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}
