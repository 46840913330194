import { createSlice } from "@reduxjs/toolkit";

const cartDrawer = createSlice({
  name: "cart slide",
  initialState: { value: false },
  reducers: {
    openCart: (state) => {
      state.value = true;
    },
    closeCart: (state) => {
      state.value = false;
    },
  },
});

export const { openCart, closeCart } = cartDrawer.actions;

export default cartDrawer.reducer;

export const getCartDrawerState = (state) => state.cartDrawer.value;
