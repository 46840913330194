import React, {useState} from 'react'
import {toast} from 'react-toastify';
import {POST_ADD_TEACHER} from '../../Constants/constants';
const teach = '/img/teach.png';

export default function Teach() {

    const [formData, setFormData] = useState({})

    const handleChange = (e) => {
        const updateData = formData;
        updateData[e.target.name] = e.target.value;
        setFormData(updateData);
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const newData = {
            ...formData,
        };

        fetch(POST_ADD_TEACHER, {
            method: 'POST',
            body: JSON.stringify(newData),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(result => {
                // console.log("result: ", result)
                toast.success("Message sent")
            }).catch(error => {
            // console.log("error: ", error)
            toast.error("Try Again")
        })


        // console.log(newData)
    }

    return (
        <div className='bg-white py-20'>
            <div className='px-5 md:px-0 container mx-auto'>
                <div className="grid grid-cols-2 md:space-x-5">
                    <div className="col-span-2 md:col-span-1">
                        <h1 className='font-extrabold text-[40px] leading-[80px] text-[#1D2130]'>Teach With Us</h1>
                        <p className='text-[18px] leading-[32.4px] text-[#1D2130]'>Fill the form below to start this
                            journey of impactng millions of lives
                            <br/>Our Friendly team would reach out to you</p>

                        <div className="contact-info">
                            <div className="icon">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >

                                </svg>
                            </div>

                        </div>
                        {/*<div className="contact-info">*/}
                        {/*    <div className="icon">*/}
                        {/*        <svg*/}
                        {/*            width="23"*/}
                        {/*            height="23"*/}
                        {/*            viewBox="0 0 23 23"*/}
                        {/*            fill="none"*/}
                        {/*            xmlns="http://www.w3.org/2000/svg"*/}
                        {/*        >*/}
                        {/*            <path*/}
                        {/*                d="M18.6875 20.8438H18.5653C4.44187 20.0316 2.43656 8.11469 2.15624 4.47781C2.13366 4.19504 2.16709 3.91059 2.25462 3.64076C2.34216 3.37094 2.48207 3.12103 2.66635 2.90537C2.85064 2.68971 3.07566 2.51253 3.32853 2.38398C3.58141 2.25543 3.85716 2.17804 4.13999 2.15625H8.10031C8.38821 2.15597 8.66956 2.24215 8.90792 2.40362C9.14628 2.56509 9.33066 2.79441 9.43718 3.06188L10.5297 5.75C10.6349 6.0113 10.661 6.29774 10.6048 6.57375C10.5485 6.84976 10.4125 7.10317 10.2134 7.3025L8.6825 8.84781C8.92164 10.2068 9.57244 11.4595 10.5469 12.4365C11.5213 13.4135 12.7723 14.0676 14.1306 14.3103L15.6903 12.765C15.8926 12.5682 16.1484 12.4353 16.4258 12.3829C16.7032 12.3305 16.9898 12.3609 17.25 12.4703L19.9597 13.5556C20.2231 13.6655 20.4479 13.8513 20.6053 14.0894C20.7628 14.3275 20.8458 14.6071 20.8437 14.8925V18.6875C20.8437 19.2594 20.6166 19.8078 20.2122 20.2122C19.8078 20.6166 19.2594 20.8438 18.6875 20.8438ZM4.31249 3.59375C4.12187 3.59375 3.93905 3.66948 3.80426 3.80427C3.66947 3.93906 3.59374 4.12188 3.59374 4.3125V4.37C3.92437 8.625 6.04468 18.6875 18.6444 19.4062C18.7388 19.4121 18.8334 19.3992 18.9229 19.3684C19.0123 19.3376 19.0948 19.2894 19.1656 19.2266C19.2364 19.1639 19.2941 19.0877 19.3354 19.0026C19.3767 18.9175 19.4007 18.8251 19.4062 18.7306V14.8925L16.6966 13.8072L14.6337 15.8556L14.2887 15.8125C8.03562 15.0291 7.18749 8.77594 7.18749 8.71125L7.14437 8.36625L9.18562 6.30344L8.10749 3.59375H4.31249Z"*/}
                        {/*                fill="black"*/}
                        {/*            />*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*    <div className="content">*/}
                        {/*        <div>+234 (0) 807 787 4589</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div id="map">

                        </div>

                        {/* Form */}
                        <form className='mt-[65px] md:mr-20' onSubmit={handleSubmit}>
                            <div className="grid grid-cols-2 space-x-5">
                                <div className="col-span-1 flex flex-col justify-start">
                                    <label htmlFor='firstName' className='text-[18px] leading-[32.4px] text-[#1D2130]'>First
                                        Name</label>
                                    <input name="firstName" onChange={handleChange}
                                           className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='text'
                                           required/>
                                </div>

                                <div className="col-span-1 flex flex-col justify-start">
                                    <label htmlFor='lastName' className='text-[18px] leading-[32.4px] text-[#1D2130]'>Last
                                        Name</label>
                                    <input name="lastName" onChange={handleChange}
                                           className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='text'
                                           required/>
                                </div>
                            </div>
                            <div className="col-span-1 flex flex-col justify-start mt-[30px]">
                                <label htmlFor='entered_message'
                                       className='text-[18px] leading-[32.4px] text-[#1D2130]'>Introduce
                                    yourself</label>
                                <textarea name='introduction' onChange={handleChange} rows={5}
                                          className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='text'
                                          required/>
                            </div>
                            <div className="col-span-1 flex flex-col justify-start mt-[30px]">
                                <label htmlFor='email'
                                       className='text-[18px] leading-[32.4px] text-[#1D2130]'>Your Website</label>
                                <input name='website' onChange={handleChange}
                                       className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='text'
                                />
                            </div>
                            <div className="col-span-1 flex flex-col justify-start mt-[30px]">
                                <label htmlFor='email'
                                       className='text-[18px] leading-[32.4px] text-[#1D2130]'>Email</label>
                                <input name='email' onChange={handleChange}
                                       className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='email'
                                       required/>
                            </div>
                            <div className="col-span-1 flex flex-col justify-start mt-[30px]">
                                <label htmlFor='entered_phone' className='text-[18px] leading-[32.4px] text-[#1D2130]'>Phone
                                    Number</label>
                                <input name='entered_phone' onChange={handleChange}
                                       className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='text'
                                       required/>
                            </div>
                            <br />
                            <div className="grid grid-cols-2 space-x-5">
                                <div className="col-span-1 flex flex-col justify-start">
                                    <label htmlFor='firstName' className='text-[18px] leading-[32.4px] text-[#1D2130]'>LinkedIn</label>
                                    <input name="linkedin" onChange={handleChange}
                                           className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='text'
                                           required/>
                                </div>

                                <div className="col-span-1 flex flex-col justify-start">
                                    <label htmlFor='lastName' className='text-[18px] leading-[32.4px] text-[#1D2130]'>
                                        Twitter</label>
                                    <input name="twitter" onChange={handleChange}
                                           className='rounded-lg p-1 border border-[#6A6A6A] mt-[15px]' type='text'
                                           />
                                </div>
                            </div>


                            <button
                                className='text-[18px] font-semibold leading-[30px] bg-[#625038] py-[17px] mt-[30px] text-white w-full rounded-xl'>Sign
                                Up
                            </button>
                        </form>
                    </div>
                    <div className="col-span-2 mt-5 md:mt-0 md:col-span-1 flex justify-end">
                        <img src={teach} alt={'teach image'}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}
