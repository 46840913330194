import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {Button, CardActionArea, CardActions} from "@mui/material";
import {useHistory} from "react-router-dom";
import {UserContext} from "../../App";
import {toast} from "react-toastify";
import {baseUrl, IMG_BASE_PATH, numToCommaAmount, POST_ADD_TO_CART} from "../../Constants/constants";
import axios from "axios";
import {useDispatch} from "react-redux";
import {addSingleItemToCart} from "../../features/cart/cartSlice";

export const CourseCard = ({data}) => {
    const history = useHistory();
    const [loggedInUser, setLoggedInUser] = React.useContext(UserContext);

    const dispatch = useDispatch();

    const addToCart = async (course) => {
        dispatch(addSingleItemToCart(course));
        const res = await axios.post(baseUrl + POST_ADD_TO_CART, {
            token: loggedInUser.user_token,
            ...course,
        });
        if (!res.data.error) {
            toast.success("Item added to cart");
        } else {
            toast.warn("Item already exists");
        }
    };

    const showSingeCourse=(data)=>{


    }

    return (
        <div className="col-span-full md:col-span-1">
            <Card
                sx={{
                    maxWidth: 345,
                    minHeight: 450,
                    marginTop: 2,
                    border: "#625038",
                    hover: "#625038",
                    boxSizing: "border-box",
                    borderWidth: 0,
                    borderStyle: "solid",
                }}
            >
                <a href={data.paystack} target="_blank">

                    <CardActionArea onClick={() => showSingeCourse(data)}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={IMG_BASE_PATH + data.image_url_small}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {data.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {data.introduction.substring(0, 350) + " (..)"}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </a>
                    <CardActions>
                        <div className="flex justify-between w-full">
              <span>
                  <a href={data.paystack} target="_blank">
                      <Button size="small" onClick={() => addToCart(data)} color="error">
              Subscribe
            </Button>
                  </a>
              </span>
                            <span style={{alignSelf: 'flex-end'}}>{numToCommaAmount(data.price)}
          </span>
                        </div>
                    </CardActions>
            </Card>
        </div>
);
};
