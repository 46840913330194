import React from 'react'
import { Link } from 'react-router-dom';


export default function PublicFooter() {
  return (
    <div className='bg-[#625038] text-center  h-[106px] flex justify-center items-center'>
      <p className='text-[20px] leading-[30px] font-[Roboto] font-bold text-white'>Copyright © 2021 <a href="https://elessonacademy.com" target="_blank">E-Lesson Academy</a></p>
    </div>
  )
}