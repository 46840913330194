import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Button, CardActionArea, CardActions} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {UserContext} from '../../App';
import {toast} from 'react-toastify';
import {IMG_BASE_PATH} from "../../Constants/constants";

export const SubscribedCourseCard = ({data}) => {

    const history = useHistory()
    const [loggedInUser, setLoggedInUser] = React.useContext(UserContext)

    const handleCardClick = () => {
        if (loggedInUser.userType === 'user') {
            toast.info("will add on Cart")
            // await Post('Cart/add', {})
        } else {
            history.push('/user-login')
        }
    }

    return (
        <div className='col-span-1'>
            <Card onClick={handleCardClick} sx={{
                maxWidth: 345,
                minHeight: 450,
                marginTop: 2,
                border:'#625038',
                hover:'#625038',
                boxSizing: 'border-box',
                borderWidth: 0,
                borderStyle: 'solid'
            }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image= {IMG_BASE_PATH +  data.course_id.image_url_small}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {data.course_id.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {data.course_id.introduction.substring(86, 350) + ' (..)'}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" color="error">
                        Start
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}
