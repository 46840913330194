import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../App";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { openCart } from "../features/cart/cartDrawer";
import { cartItems } from "../features/cart/cartSlice";

const pages = [];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveAppBar = () => {
  const [loggedInUser, setLoggedInUser] = React.useContext(UserContext);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const cart = useSelector(cartItems);

  const history = useHistory();

  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function navigateToDashboard() {
    history.push("/");
  }
  function navigateToCourses() {
    history.push("/all-courses");
  }
  function navigateToAccount() {
    history.push("/account");
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#fff", color: "#18558c" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ marginLeft: "30px", marginTop: "10px", marginBottom: "10px" }}
          >
            <img  onClick={navigateToDashboard} src="/img/elesson.png" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={() => history.push("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            E-Lesson Academy
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {
              !loggedInUser.user_token ? (
                <Box>
                  <Link to="/login">
                    <Button
                      variant="text"
                      sx={{
                        color: "#18558c",
                        marginRight: "30px",
                      }}
                    >
                      Login
                    </Button>
                  </Link>
                  <Link to="/register">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#18558c",
                        color: "#F6C88D",
                      }}
                    >
                      Register
                    </Button>
                  </Link>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div className="space-x-6">
                    <Button onClick={navigateToDashboard}>Dashboard</Button>
                    <Button onClick={navigateToCourses}>Courses</Button>
                    <Button onClick={navigateToAccount}>Account</Button>
                    <span className="relative">
                      {cart.length !== 0 && (
                        <span className="absolute text-xs font-bold rounded-full text-blue-600 bg-white">
                          {cart.length}
                        </span>
                      )}
                      <IconButton sx={{ paddingRight: "16px", color: "blue" }}>
                        <ShoppingCart
                          onClick={() => {
                            dispatch(openCart());
                          }}
                        />
                      </IconButton>
                    </span>
                    <Button
                      onClick={() => setLoggedInUser({ user_token: "" })}
                      sx={{ backgroundColor: "#18558c" }}
                      variant="contained"
                    >
                      LogOut
                    </Button>
                  </div>
                </Box>
              )
            }
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
