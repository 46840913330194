import React, { useEffect, useRef, useState } from "react";
import {IMG_BASE_PATH, numToCommaAmount, toCommaAmount} from "../../Constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { closeCart, getCartDrawerState } from "../../features/cart/cartDrawer";
import axios from "axios";
import { UserContext } from "../../App";
import CartItem from "./CartItem";
import {
  addToCart,
  cartItems,
  getSubTotal,
} from "../../features/cart/cartSlice";

export default function Cart() {
  const cartDrawer = useSelector(getCartDrawerState);
  const dispatch = useDispatch();
  const [loggedInUser, setLoggedInUser] = React.useContext(UserContext);
  const cartData = useSelector(cartItems);
  const effRan = useRef(false);

  const subTotal = useSelector(getSubTotal);

  async function getCartData() {
    const res = await axios.post("http://localhost:5000/user/Cart/", {
      token: loggedInUser.user_token,
    });

    console.log(res.data);

    dispatch(
      addToCart({ cartData: res.data.allCarts, subTotal: res.data.subTotal })
    );
  }

  useEffect(() => {
    if (!effRan.current) {
      getCartData();
    }

    return () => (effRan.current = true);
  }, [cartDrawer]);

  if (cartDrawer) {
    return (
      <>
        <div
          className="relative z-10"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <div className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <h2
                          className="text-lg font-medium text-gray-900"
                          id="slide-over-title"
                        >
                          Shopping cart
                        </h2>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            onClick={() => {
                              dispatch(closeCart());
                            }}
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">Close panel</span>
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {cartData.map((item) => {
                              return <CartItem data={item} />;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Subtotal</p>
                        <p>{numToCommaAmount(subTotal)}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping and taxes calculated at checkout.
                      </p>
                      <div className="mt-6">
                        <a
                          href="#"
                          className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                        >
                          Checkout
                        </a>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or
                          <br />
                          <button
                            onClick={() => dispatch(closeCart())}
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
