import React, { createContext, useState } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import AllUsers from "./Pages/AllUsers";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./Routes/PrivateRoute";
import NotFound from "./Pages/NotFound/NotFound";
import Cookies from "js-cookie";
import axios from "axios";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Home from "./Pages/Home/Home";
import PublicLayout from "./Layout/PublicLayout";
import Pricing from "./Pages/Pricing/Pricing";
import Teach from "./Pages/Teach/Teach";
import AllCourses from "./Pages/AllCourses/AllCourses";
import ContactUs from "./Pages/ContactUs/ContactUs";

export const UserContext = createContext();

function App() {
  const [loggedInUser, setLoggedInUser] = useState({
    token: Cookies.get("user_token"),
  });
  const [selectedService, setSelectedService] = useState({});
  axios.defaults.headers.common["Authorization"] =
    Cookies.get("user_token") ||
    loggedInUser.user_token ||
    "token pawa jay nai";
  return (
    <UserContext.Provider
      value={[
        loggedInUser,
        setLoggedInUser,
        selectedService,
        setSelectedService,
      ]}
    >
      <Router>
        <Switch>
          <Route exact path="/">
            {loggedInUser.user_token ? (
              <Layout>
                <Dashboard />
              </Layout>
            ) : (
              <Redirect to="/home" />
            )}
          </Route>
          <Route exact path="/home">
            <PublicLayout>
              <Home />
            </PublicLayout>
          </Route>

          <Route exact path="/pricing">
            <PublicLayout>
              <Pricing />
            </PublicLayout>
          </Route>
          <Route exact path="/teach">
            <PublicLayout>
              <Teach />
            </PublicLayout>
          </Route>
          <Route exact path="/contact-us">
            <PublicLayout>
              <ContactUs />
            </PublicLayout>
          </Route>
          <PrivateRoute exact path="/all-users">
            <Layout>
              <AllUsers />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/all-courses">
            <Layout>
              <AllCourses />
            </Layout>
          </PrivateRoute>
          {/* <PrivateRoute path="/admin">
            <Admin></Admin>
          </PrivateRoute> */}
          <Route path="/login">
            <PublicLayout>
              <Login />
            </PublicLayout>
          </Route>
          <Route path="/register">
            <PublicLayout>
              <Register />
            </PublicLayout>
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <ToastContainer />
      </Router>
    </UserContext.Provider>
  );
}

export default App;
