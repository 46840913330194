import { createSlice } from "@reduxjs/toolkit";

const cartSlideSlice = createSlice({
  name: "cart data",
  initialState: { data: [], subTotal: 0, loading: true },
  reducers: {
    addToCart: (state, action) => {
      state.data = action.payload.cartData;
      state.subTotal = action.payload.subTotal;
    },
    addSingleItemToCart: (state, action) => {
      state.data.push(action.payload);
      state.subTotal += action.payload.price;
    },
    loadCart: (state, action) => {
      state.data = action.payload;
    },
    removeItemFromCart: (state, action) => {
      state.data = state.data.filter((item) => item._id !== action.payload.id);
      state.subTotal = state.subTotal - action.payload.price;
    },

    updateSubTotal: (state) => {
      state.subTotal = state.data.reduce((total, current) => {
        // if (current.offerPrice) {
        //   return total + current.offerPrice;
        // }
        return total + current.price;
      }, 0);
    },
    setCartLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  addToCart,
  addSingleItemToCart,
  removeItemFromCart,
  updateSubTotal,
  loadCart,
  setCartLoading,
} = cartSlideSlice.actions;

export default cartSlideSlice.reducer;

export const cartItems = (state) => state.cart.data;

export const cartLoading = (state) => state.cart.loading;

export const getSubTotal = (state) => state.cart.subTotal;
