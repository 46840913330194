import Cookies from "js-cookie";

export const headerOptions = {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    user_token = Cookies.get("user_token"),
    // baseUrl = "http://localhost:5000/user";
    baseUrl = "https://9cxrmmeetp.us-east-1.awsapprunner.com/user";

export const CONTACT_API_URL =
    "https://01hrl8d13h.execute-api.us-east-1.amazonaws.com/v2/contact";
export const GET_ALL_ARTICLES_URL =
    "https://d66k64jwpa.us-east-1.awsapprunner.com/user/news/";

export const LOGIN_URL = "login";
export const REGISTER_URL = "register";
export const IMG_BASE_PATH = "img/web/";
export const GET_ALL_COURSES_URL = "CourseOpen/";
export const GET_COURSES_BY_ID_URL = "SubscribedCourse/";
export const GET_SUBSCRIBED_COURSES_URL = "SubscribedCourses/";
export const POST_ADD_TO_CART = "/Cart/add";
export const POST_ADD_TEACHER = "/Author/add";
export const formatPrice = (x, currency) => {
    switch (currency) {
        case "BRL":
            return x.toFixed(2).replace(".", ",");
        default:
            return x.toFixed(2);
    }
};
export const numToCommaAmount = (numInput) => {
    const strInput = numInput.toString()

    return toCommaAmount(strInput)
}

export const toCommaAmount = (strOrig) => {
    if (strOrig !== null) {
        if (typeof strOrig == 'number') {
            strOrig = strOrig.toString();
        }
        let strLen = strOrig.length;
        let builder = "";
        let newamt = "";

        if (strOrig.includes(".")) {
            strLen = strLen - 3;
            switch (strLen) {
                case strLen < 4 :
                    newamt = strOrig;
                case strLen === 4:
                    builder = strOrig.slice(0, 1) + "," + strOrig.slice(1, strLen);
                    newamt = builder;
                case strLen === 5:
                    builder = strOrig.slice(0, 2) + "," + strOrig.slice(2, strLen);
                    newamt = builder;
                case strLen === 6:
                    builder = strOrig.slice(0, 3) + "," + strOrig.slice(3, strLen);
                    newamt = builder;
                case strLen === 7:
                    builder =
                        strOrig.slice(0, 1) +
                        "," +
                        strOrig.slice(1, 5) +
                        "," +
                        strOrig.slice(4, strLen);
                    newamt = builder;
                case strLen === 8:
                    builder =
                        strOrig.slice(0, 2) +
                        "," +
                        strOrig.slice(2, 6) +
                        "," +
                        strOrig.slice(5, strLen);
                    newamt = builder;
                case strLen === 9:
                    builder =
                        strOrig.slice(0, 3) +
                        "," +
                        strOrig.slice(3, 7) +
                        "," +
                        strOrig.slice(6, strLen);
                    newamt = builder;
                case strLen === 10:
                    builder =
                        strOrig.slice(0, 1) +
                        "," +
                        strOrig.slice(1, 5) +
                        "," +
                        strOrig.slice(4, 8) +
                        "," +
                        strOrig.slice(7, strLen);
                    newamt = builder;
                case strLen === 11:
                    builder =
                        strOrig.slice(0, 2) +
                        "," +
                        strOrig.slice(2, 6) +
                        "," +
                        strOrig.slice(5, 9) +
                        "," +
                        strOrig.slice(8, strLen);
                    newamt = builder;
                default:
                    newamt = strOrig;
            }
        } else {

            switch (strLen) {
                case  1:
                case  2:
                case  3:
                    newamt = strOrig;
                    break;

                case 4:
                    builder = strOrig.slice(0, 1) + "," + strOrig.slice(1, strLen);
                    newamt = builder;
                    break;

                case 5:
                    builder = strOrig.slice(0, 2) + "," + strOrig.slice(2, strLen);
                    newamt = builder;
                    break;

                case 6:
                    builder = strOrig.slice(0, 3) + "," + strOrig.slice(3, strLen);
                    newamt = builder;
                    break;

                case 7:
                    builder =
                        strOrig.slice(0, 1) +
                        "," +
                        strOrig.slice(1, 5) +
                        "," +
                        strOrig.slice(4, strLen);
                    newamt = builder;
                    break;

                case 8:
                    builder =
                        strOrig.slice(0, 2) +
                        "," +
                        strOrig.slice(2, 6) +
                        "," +
                        strOrig.slice(5, strLen);
                    newamt = builder;
                    break;

                case 9:
                    builder =
                        strOrig.slice(0, 3) +
                        "," +
                        strOrig.slice(3, 7) +
                        "," +
                        strOrig.slice(6, strLen);
                    newamt = builder;
                    break;

                case 10:
                    builder =
                        strOrig.slice(0, 1) +
                        "," +
                        strOrig.slice(1, 5) +
                        "," +
                        strOrig.slice(4, 8) +
                        "," +
                        strOrig.slice(7, strLen);
                    newamt = builder;
                    break;

                case 11:
                    builder =
                        strOrig.slice(0, 2) +
                        "," +
                        strOrig.slice(2, 6) +
                        "," +
                        strOrig.slice(5, 9) +
                        "," +
                        strOrig.slice(8, strLen);
                    newamt = builder;
                    break;
                default:
                    newamt = strOrig;
            }
        }

        return "\u20A6" + newamt;
    }
};
