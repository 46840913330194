import { Button, TextField } from '@mui/material'
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Post } from '../../Adapters/xhr';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/Buttons/SubmitButton';
import CustomTextField from '../../components/InputFields/CustomTextField';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../App';

export default function Login() {

  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  const [formValues, setFormValues] = useState({})
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  let { from } = location.state || { from: { pathname: "/" } };
  const history = useHistory()


  const handleChange = (e) => {
    const newFormValues = formValues;
    newFormValues[e.target.name] = e.target.value;
    setFormValues(newFormValues)
  }

  const loginSubmit = async(e) => {
    e.preventDefault()

    setLoading(true)
    try {
      const response = await Post("login", formValues)
      setLoggedInUser({user_token: response.data.token, user: response.data.user})
      Cookies.set("user_token", response.data.token)
      toast.success("Login successfull.")
      history.push(from)
      setLoading(false)
    } catch (error) {
      toast.warn("Email or Password not Matched")
      setLoading(false)
    }

    console.log({formValues})
  }

  return (
    <div className='container mx-auto pb-10 md:pb-0 bg-white min-h-screen my-20'>
      <div className='container mx-auto'>
        <div className='grid grid-cols-1 md:grid-cols-2 min-h-screen'>
          <div className='col-span-1 hidden bg-[#18558c30] h-screen md:flex justify-cesnter items-center'>
            <div>
            <h1 className='ml-10 font-semibold text-[50px] leading-[80px]'>Login</h1>
            <img src='/img/elesson-big-logo.png'/>
            </div>
          </div>
          {/* login fomr */}
          <div className='md:col-span-1'>
          <form className='flex flex-col mx-10 md:mx-20 mt-20' onSubmit={loginSubmit}>
            <h1 className='text-center text-2xl md:mt-[70px]'>Login</h1>  
            <CustomTextField name="email" onChange={handleChange} label="Email" type='email' required={true} />
            <CustomTextField name="password" onChange={handleChange} label="Password" type='password' required={true} />
            <SubmitButton loading={loading}/>
          </form>
          </div>
        </div>
      </div>
    </div>
  )
}
