import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useHistory} from "react-router-dom";
import axios from "axios";
import NewsCard from "../../components/Cards/NewsCard";
import {GET_ALL_ARTICLES_URL, GET_ALL_COURSES_URL,} from "../../Constants/constants";
import {Get} from "../../Adapters/xhr";
import Typography from "@mui/material/Typography";
import {CourseCard} from "../../components/Cards/CourseCard";

export default function Home() {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [allNewsArticles, setAllNewsArticles] = useState([]);
    const [allCourses, setAllCourses] = useState([]);

    const getArticles = async (setLoading, setAllNewsArticles) => {
        try {
            setLoading(true);
            const {data} = await axios.get(GET_ALL_ARTICLES_URL);
            setAllNewsArticles(data);
        } catch (err) {
            setLoading(false);
        }
    };
    const getAllCourses = async () => {
        try {
            setLoading(true);

            const {data} = await Get(GET_ALL_COURSES_URL);
            // setAllCourses(prevCourses => [ ... prevCourses, data]);
            setAllCourses(data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };
    useEffect(() => {
        (async () => {
            getArticles(setLoading, setAllNewsArticles);
            getAllCourses();
        })();
    }, []);

    return (
        <div className="p-5">
            {/* Hero section */}
            <div className="container px-5 md:px-0 mx-auto text-center space-x-2 pb-10">
                <Typography gutterBottom variant="h3" component="div">
                    Video Tutorials & Digital Solutions For Eager Learners
                </Typography>

                <p className="text-black">
                    Sign up, study, complete a course, and receive a genuine certificate.
                </p>
                <p className="text-black">
                    Gain Access to, and reply to other people's questions and discussion
                    forum posts which are responded to by other learners and mentors.
                </p>

                <div className="grid grid-cols-4 gap-4 pt-6">
                    {loading
                        ? "Loading..."
                        : allCourses.length > 0
                            ? allCourses.map((data, index) => {
                                return <CourseCard key={index} data={data}/>;
                            })
                            : ""}
                </div>
            </div>

            {/* Info section */}
            <div className="bg-white rounded">
                <div className="container px-5 md:px-0 mx-auto py-20">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 md:col-span-5">
                            <img src="/img/info-img.png"/>
                        </div>
                        <div className="col-span-12 md:col-span-7">
                            <h1 className="font-[Roboto] mt-5 md:mt-0 font-bold text-[32px] text-center md:text-left md:text-[40px] md:leading-[30px] text-black">
                                Unlock Your Potential with E-Lesson Academy
                            </h1>
                            <h2>- Elevate Your Learning Journey Today!</h2>
                            <h3>Empower Yourself through Video Tutorials, Live Lessons & Digital Solutions</h3>
                            <p className="text-[18px] md:text-[30px] text-black text-center md:text-left md:leading-[54px] my-[60px]">
                                Welcome to E-Lesson Academy, where learning meets innovation. Embark on a transformative
                                educational experience with our engaging video tutorials, live lessons, and cutting-edge
                                digital solutions. Whether you're a student, professional, or lifelong learner, our
                                platform is designed to cater to your intellectual curiosity and empower you to achieve
                                your goals.
                            </p>

                            <button
                                onClick={() => history.push("/contact-us")}
                                className="text-white mx-auto md:mx-0 px-5 py-2 rounded-md mt-7 flex items-center"
                            >
                                Contact Us <img className="ml-3" src="/img/right-arrow.png"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Our Services */}
            <div>
                <div className="container px-5 md:px-0 mx-auto py-20">
                    <div className="grid grid-cols-2 md:space-x-7">

                    </div>
                </div>
            </div>

            {/* Our Recent Work */}
            <div className="bg-white w-screen overflow-hidden">
                <div className="container mx-auto py-20">
                    <h1 className="text-black text-center mb-20 font-[Roboto] font-bold text-[32px] md:text-[50px] md:leading-[30px]">
                         The Academy
                    </h1>
                    <div>
                        <p className="text-[18px] md:text-[30px] text-black text-center md:text-left md:leading-[54px] my-[60px]">
                            At E-Lesson Academy, education is not just a destination; it's a dynamic journey. Sign
                            up now to explore a world of knowledge, study at your own pace, and complete courses
                            that align with your interests and aspirations. Upon completion, receive a genuine
                            certificate that validates your commitment to continuous learning and personal growth.
                        </p>
                        <p className="text-[18px] md:text-[30px] text-black text-center md:text-left md:leading-[54px] my-[60px]">
                            <b>Interactive Learning Community</b>: Join a vibrant community of learners where you
                            can post questions, engage in discussions, and benefit from the collective knowledge of
                            both peers and experienced mentors.
                        </p>
                        <p className="text-[18px] md:text-[30px] text-black text-center md:text-left md:leading-[54px] my-[60px]">
                            <b>Real-Time Collaboration</b>: Gain access to a discussion forum where your questions
                            are not just heard but answered promptly by a network of learners and mentors. Connect,
                            share insights, and collaborate in real-time to enhance your understanding and skills.
                        </p>
                        <p className="text-[18px] md:text-[30px] text-black text-center md:text-left md:leading-[54px] my-[60px]">
                            E-Lesson Academy is more than just a learning platform; it's a supportive community that
                            nurtures your intellectual curiosity and fosters meaningful connections. Dive into a
                            world of knowledge, connect with like-minded individuals, and chart your path to success
                            with E-Lesson Academy. Sign up now and take the first step toward a brighter, more
                            informed future.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
