import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { register } from "../../Adapters/auth/register";
import SubmitButton from "../../components/Buttons/SubmitButton";
import CustomTextField from "../../components/InputFields/CustomTextField";

export default function Register() {
  const history = useHistory();

  const [formValues, setFormValues] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const newFormValues = formValues;
    newFormValues[event.target.name] = event.target.value;
    setFormValues(newFormValues);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log({ formValues });
    setLoading(true);

    try {
      let response;

        response = await register("user-register", {
          ...formValues,
        });

  if (response?.data?.secret_code == null) {
        toast.success("User Register Successfull");
        history.push("/login");
      }
      setLoading(false);
    } catch (error) {
      toast.warn("Register Failed. Try Again");
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto bg-white min-h-screen md:my-20">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
          <div className="hidden md:col-span-1 bg-[#18558c30] h-screen md:flex justify-cesnter items-center">
            <div>
              <h1 className="ml-10 font-semibold text-[50px] leading-[80px]">
                Sign Up
              </h1>
              <img src="/img/elesson-big-logo.png" alt="elesson-big-logo.png" />
            </div>
          </div>
          {/* login fomr */}
          <div className="md:col-span-1">
            <div className="mx-auto px-10 md:px-20 mt- ">
              <form className="flex flex-col" onSubmit={handleSubmit}>
                <h1 className="text-center text-2xl mt-[70px]">Register</h1>
                <CustomTextField
                  name="firstNsme"
                  onChange={handleChange}
                  label="First Nsme"
                  type="text"
                  required={true}
                />
                <CustomTextField
                  name="lastName"
                  onChange={handleChange}
                  label="Last Name"
                  type="text"
                  required={true}
                />
                <CustomTextField
                  name="email"
                  onChange={handleChange}
                  label="Email"
                  type="email"
                  required={true}
                />
                <CustomTextField
                  name="password"
                  onChange={handleChange}
                  label="Password"
                  type="password"
                  required={true}
                />

                <SubmitButton loading={loading} />
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
