import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../App";
import Cookies from "js-cookie";
import {
  GET_ALL_COURSES_URL,
  GET_SUBSCRIBED_COURSES_URL,
} from "../../Constants/constants";
import { Get, Post } from "../../Adapters/xhr";
import Typography from "@mui/material/Typography";
import { SubscribedCourseCard } from "../../components/Cards/SubscribedCourseCard";
import { CourseCard } from "../../components/Cards/CourseCard";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  const [allCourses, setAllCourses] = useState([]);
  const [allSubscribedCourses, setAllSubscribedCourses] = useState([]);
  const [allSubscribedIdLists, setAllSubscribedIdLists] = useState([]);
  const location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const history = useHistory();

  const getAllCourses = async () => {
    try {
      setLoading(true);

      const { data } = await Get(GET_ALL_COURSES_URL);
      // setAllCourses(prevCourses => [ ... prevCourses, data]);
      setAllCourses(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getAllSubscribedCourses = async () => {
    console.log("loggedInUser.user_token is.....", loggedInUser.user_token);

    try {
      const cookie_token = Cookies.get("user_token");

      console.log("cookie_token >>", cookie_token);

      const data_reg = await Post(GET_SUBSCRIBED_COURSES_URL, {
        token: cookie_token,
      });
      console.log("log:", data_reg);
      // setAllSubscribedCourses(prevCourses => [ ... prevCourses, data]);
      console.log("<<< subscribed starts >>>");
      console.log(data_reg.data);
      setAllSubscribedCourses(data_reg.data);
      console.log("<<< subscribed ends >>>");
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCourses();
    getAllSubscribedCourses();
  }, []);

  return (
    <div className="container my-5 mx-auto text-black">
      {/* <Typography gutterBottom variant="h4" component="div">Dashboard</Typography> */}

      <div className="w-full h-full flex justify-center items-center">
        <h1 className="text-3xl">
          Welcome to <span>E-Lesson Academy</span>
        </h1>
      </div>
      {/* <div>
        <Typography gutterBottom variant="h5" component="div">
          My Subscribed Courses
        </Typography>
      </div> */}

      <div className="grid grid-cols-4 gap-4">
        {loading
          ? "Loading..."
          : allSubscribedCourses.length > 0
          ? allSubscribedCourses.map((data, index) => {
              return <SubscribedCourseCard key={index} data={data} />;
            })
          : ""}
      </div>

      <hr />

      <div>
        <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: '35px'}} >
          All Courses
        </Typography>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {loading
          ? "Loading..."
          : allCourses.length > 0
          ? allCourses.map((data, index) => {
              return <CourseCard key={index} data={data} />;
            })
          : ""}
      </div>
    </div>
  );
}
