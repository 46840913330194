import React, { useRef, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { CourseCard } from "../../components/Cards/CourseCard";
import Cart from "../../components/Cart/Cart";

export default function AllCourses() {
  const effRan = useRef(false);
  const [courses, setCourses] = useState([]);

  async function getAllCourses() {
    const res = await axios.get("http://localhost:5000/user/CourseOpen/");

    setCourses(res.data);
  }

  useEffect(() => {
    if (!effRan.current) {
      getAllCourses();
    }

    return () => (effRan.current = true);
  });
  return (
    <section className="w-screen p-4">
      <h1 className="text-center font-bold text-2xl">All courses</h1>
      <main className="flex flex-col md:flex-row items-center justify-center flex-wrap md:space-x-4">
        {courses.map((course) => {
          return <CourseCard data={course} />;
        })}
      </main>
    </section>
  );
}
