import { Grid } from "@mui/material";
import React from "react";
import Cart from "../components/Cart/Cart";
import ResponsiveAppBar from "./AppBar";
import SideBar from "./SideBar";

export default function Layout({ children }) {
  return (
    <div>
      <ResponsiveAppBar />
      <div className="flex">
        {/*<SideBar/>*/}
        <Cart />

        <div className="p-[26px] w-full">{children}</div>
      </div>
      <footer className="py-4 bg-black text-white text-center">
        &copy; 2022 E-Lesson Academy All right Reserved
      </footer>
    </div>
  );
}
